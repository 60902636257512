.home-headings-1 h5 {
    font-size: 20px;
}
.home-main {
    max-width: calc(100% - 280px);
    position: relative;
    width: 100%;
    margin-left: auto;
}
.home-menu-bar img {
    width: 25px;
    display: block;
}
.home-menu-bar {
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-logo-1 img {
    width: 100px;
    padding: 5px 0;
    object-fit: contain;
}
.home-nav {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    /* box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px; */
    border-bottom: 1px solid #eeeeee94;
    box-shadow: 0 4px 30px rgb(0 0 0 / 8%);
}
.home-win-sign-1 i {
    position: relative;
    line-height: normal;
    color: #0163a2;
}
.home-header {
    
}
.sliderBox{
    max-width: calc(100% - 32px);
    position: relative;
    margin: 0 auto;
    background-color: #e5d8c1;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1.5rem;
}
.home-header img {
    /* border-radius: 25px;
    height: 300px;
    max-width: 800px;
    position: relative;
    border-radius: 15px;
    width: 100%;
    right: 0; */
    max-width: 100%;
    max-height: 250px;
    display: block;
    margin-left: auto;
    padding-top: 30px;
}
.home-countdown-1 h6 {
    background-color: orange;
    width: 46px;
    height: 46px;
    color: #fff;
    padding: 6px;
    border-radius: 17px;
    font-family: var(--f3) !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
}
.home-timer .dots {
    margin: 0 6px;
    font-weight: bold;
    font-size: 22px;
    line-height: normal;
    color: #000;
}
.home-timer {
    display: flex;
    align-items: center;
    margin: 14px 0 10px;
}
.home-cnt-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    padding-left: 50px;
}
.home-cnt-img>h6 {
    font-family: var(--f3);
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bold;
}
/* .home-timer hr {
    position: relative;
    top: 13px;
    height: 20px;
} */
.home-btn-enter button {
    position: relative;
    padding: 9px 23px 9px;
    border: none;
    background: #3fab3f;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 7px;
    margin-top: 10px;
    box-shadow: inset 0 -3px 1px rgb(0 0 0 / 10%);
}
.home-btn-enter button i {
    font-size: 12px;
    margin-left: 4px;
}
.home-btn-enter button:hover i {
    transform: rotateZ(360deg);
    transition: all .3s ease;
}
.home-nav button {
    background-color: whitesmoke;
}
.home-sec-1 {
    display: flex;
    justify-content: space-between;
}
.home-cate-img-1 img {
    width: 100%;
    object-fit: cover;
    display: block;
    height: 250px;
}
button {
    border: none;
}
.home-cate-cnt-1 h5 {
    font-weight: bold;
    font-size: 20px;
    color: #0264a1;
    margin-bottom: 5px;
}
/* .home-see-all button {
    background-color: white;
} */
.home-cate-cnt-1 p {
    margin-bottom: 0;
    font-size: 13px;
}
.home-cate-cnt-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 25px;
    text-align: left;
}
section.home-footer button {
    background-color: white;
}
.home-category {
    position: relative;
    background-color: white;
    overflow: hidden;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    border-radius: 7px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 24px rgb(0 0 0 / 6%);
    transition: all .3s ease;
    cursor: pointer;
}
.home-category:hover {
    transform: translateY(-5px);
}
.home-sec-3 {
    display: flex;
}
.home-see-all a {
    text-decoration: none;
    color: black;
}
.home-icon-5 button {
    background-color: white;
    border-radius: 30px 30px 0 0;
}
.section_title .btn {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: none !IMPORTANT;
    padding: 0;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #0264a1 !important;
    display: flex;
    align-items: center;
}
.section_title .btn i {
    flex: 0 0 27px;
    width: 27px;
    height: 27px;
    background-color: #0264a1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-left: 5px;
    color: #fff;
    line-height: normal;
}
.section_title .btn:hover i {
    transform: rotateZ(360deg);
    transition: all .3s ease;
}
.section_title h5 {
    margin-bottom: 0 !important;
    font-weight: bold;
}
.section_title {
    padding-bottom: 10px;
    margin-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #eee;
}
section.home-footer button p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: gray;
}
section.home-footer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: relative;
    margin-top: 3rem;
    font-size: 20px;
    background-color: white;
    /* margin-left: -20px;
    margin-right: -20px; */
    box-shadow: 27px -8px 50px rgb(0 0 0 / 8%);
    /* box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px; */
}
.all-ul ul{
    margin: 0;
    list-style: none;
    min-height: calc(100vh - 184.6px);
    max-height: calc(100vh - 184.6px);
    overflow: auto;
    padding: 20px !important;
}
.all-ul ul::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
.all-ul ul::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 1px solid #eee;
}
  
  /* Handle */
 .all-ul ul::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
}
.sidebar ul li button {
    width: 100%;
    text-align: left;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0 !important;
}
.sidebar ul li button span:first-child i {
    margin: 0;
}
.sidebar ul li button span:first-child {
    background-color: #f2f7fa;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    flex: 0 0 30px;
    line-height: normal;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    color: #0264a1;
    margin-right: 8px;
    transition: all .3s ease;
}
.sidebar ul li:hover button span:first-child {
    background-color: #0264a1;
    color: #fff;
}
.sidebar ul li button span:last-child {
    margin-bottom: 10px;
    display: inline-flex;
    height: 30px;
    align-items: center;
    border-bottom: 1px solid #eee;
    flex: 1;
    padding-bottom: 10px;
    box-sizing: content-box;
    transition: all .3s ease;
}
.sidebar ul li button:hover span:last-child{
    color: #0264a1;
}
.sidebar ul li:last-child button span:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}
.all-ul {
    position: relative;
    /* bottom: 35px; */
}
.home-icon-1 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}
.home-icon-1 a {
    text-decoration: none;
    color: black;
}
.home-content-1 p {
    position: relative;
    top: -18px;
    color: #8e8fa0;
}
.home-icon-5 a {
    position: relative;
    bottom: 1.5rem;
    text-decoration: none;
    color: black;
}

.home-icon-5 img {
    width: 60px;
    margin-top: -30px;
    border-radius: 50%;
    background-color: #fff;
    display: block;
    border: 1px solid #fff;
    margin-bottom: 2px;
}
.home-profile h3{
    position: relative;
    left: 4rem;
}
.home-profile p{
    position: relative;
    left: 4rem;
}

.home-profile {
    background-color: #0264a1;
    padding: 20px 0;
    position: relative;
}
.home-nav button {
    background: white;
}
/* .sidebar ul li button:hover {
    color: white;
    background-color: #0264a1;
} */

.sidebar {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 280px;
    width: 100%;
    height: 100vh;
    transition: all 0.5s ease;
}
.profile {
    background-color: #0264a1;
    padding: 20px 0;
    position: relative;
}
.sidebar ul li {
    list-style: none;
}
.sidebar ul li a {
    display: block;
    padding: 20px 50px;
    color: black;
    font-size: 16px;
    position: relative;
    text-decoration: none;
}
.sidebar ul li a .icon {
    color: black;
    width: 30px;
    display: inline-block;
}
.sidebar .profile img {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    object-fit: cover;
}
.sidebar .profile h3 {
    color: white;
    margin: 10px 0 0px;
}

.profile h3 {
    text-align: center;
}
.sidebar .profile .cut {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: inherit;
}
.sidebar .profile p {
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
}

.profile p {
    text-align: center;
}
ul {
    position: relative;
     bottom: 0px!important; 
}
.sidebar ul li a:hover {
    background-color: #0264a1;
    color: white;
}
ol,
ul {
  padding-left: 0rem!important;
}
ul button i {
    margin-right: 6px;
}
.sidebar .profile #close-button {
    padding: 0;
    border-radius: 10px;
    height: 30px;
    width: 30px;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    /* display: flex !important; */
    justify-content: center;
    align-items: center;
    line-height: normal !important;
}
.sidebar.my-show .profile #close-button {
    display: flex !important;
}
.home-sidebar .home-profile img{
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    object-fit: cover;
}
.home-sidebar .home-profile h3{
    color: white;
    margin: 10px 0 5px;
}
.home-sidebar .home-profile p{
    color: rgb(206, 284, 253);
    font-size: 14px;
}
.home-sidebar ul li{
    list-style: none;
}
button.button-handle {
    /* margin-right: 1rem; */
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
    width: 100%;
    height: 100%;
}
.home-sidebar ul li button {
    display: block;
    padding: 20px 30px;
    color: black;
    font-size: 16px;
    position: relative;
    text-decoration: none;
    width: 100%;
    position: relative;
    right: 0rem;
    top: 2.19rem;
    background-color: #3292cd;
    text-align: left;
}
.home-sidebar ul li button .icon{
    color: black;
    width: 30px;
    display: inline-block;
}

.sidebar.my-show{
    transform: translateX(0%);
    z-index: 99;
    visibility: visible;
    opacity: 1;
    pointer-events: unset;
}

.home-sidebar ul li button:hover,
.home-sidebar ul li button:active{
    color: white;
    background-color: #0264a1;
    border-bottom: 2px solid rgb(5, 68, 104);

}

.home-sidebar ul li button:hover .icon,
.home-sidebar ul li button:active .icon{
    color: white;
}

.home-sidebar ul li button:hover::before,
.home-sidebar ul li button:active::before{
    display: block;
}
@media  screen and (max-width: 768px) {
    .home-main {
        max-width: 100%;
    }
    .sidebar {
        max-width: 250px;
        height: 100%;
        transform: translateX(-100%);
        z-index: 99;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }
    .sidebar.show {
        transform: translateX(0%);
        z-index: 99;
        visibility: visible;
        opacity: 1;
        pointer-events: unset;
    }
}
button.button-handle{
    padding: 0;
}

.home-sidebar-smooth{
    /* opacity: 0; */
    transition: opacity 0.6s linear;
}

.home-sidebar-smooth-apply{
    opacity: 1;
}



@media screen and (max-width: 767px) {
    .home-cnt-img {
        padding-left: 20px;
    }
    .home-header img {
        height: 210px;
        object-position: 60% 100%;
        object-fit: cover;
    }
    .home-countdown-1 h6 {
        font-size: 13px;
        height: 32px;
        width: 32px;
        border-radius: 9px;
        line-height: normal;
        padding: 0;
    }
    .home-timer .dots {
        margin: 0 4px;
        font-size: 14px;
        line-height: normal;
    }
    .home-cnt-img>h6 {
        font-size: 18px;
        max-width: 220px;
    }
    .home-timer {
        margin: 8px 0 1px;
    }
    .home-cate-cnt-1 {
        padding: 15px 20px;
    }
    .home-cate-img-1 img {
        height: 170px;
    }
    .section_title {
        margin-bottom: 10px;
    }
    .home-cate-cnt-1 h5 {
        font-size: 18px;
    }
}
@media screen and (max-width: 575px){
    section.home-footer button p {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .5px;
    }
}
@media screen and (max-width: 475px){
    .home-cnt-img>h6 {
        font-size: 16px;
        max-width: 200px;
    }
    .home-countdown-1 h6 {
        font-size: 11px;
        height: 29px;
        width: 29px;
    }
    .home-timer .dots {
        margin: 0 2px;
        font-size: 13px;
    }
    .home-btn-enter button {
        font-size: 13px;
        padding: 7px 17px;
    }
    .section_title h5 {
        font-size: 16px;
    }
    .section_title .btn i {
        flex: 0 0 23px;
        width: 23px;
        font-size: 10px;
        height: 23px;
    }
    .home-logo-1 img {
        width: 80px;
    }
    .home-menu-bar img {
        width: 20px;
    }
}