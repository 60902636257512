:root{
  --f1: 'Poppins', sans-serif;
  --f2: 'Questrial', sans-serif;
  --f3: 'Red Hat Display', sans-serif;
}
body{
  font-family: var(--f1) !important;
  font-size: 14px;
  background-color: #f8f8f8 !IMPORTANT;
}
.Toastify__toast{
  font-family: var(--f1) !important;
}
h1,h2,h3,h4,h5{
  font-family: var(--f3) !important;
}
.px-20{
  padding-left: 20px;
  padding-right: 20px;
}
.pb-20{
  padding-bottom: 20px;
}
.full-height{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (max-width: 475px){
  section.home-footer {
    font-size: 16px;
    margin-top: 2rem;
  }
}