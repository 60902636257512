.showscore-main {
    max-width: 575px;
    min-height: 100vh;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 0px;
    z-index: 1;
}
.showscore-sec-2-cnt.prfl-1 {
    padding: 16px;
    margin: -50px auto 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    max-width: calc(100% - 32px);
    text-align: center;
}
.showscore-sec-2-cnt.prfl-1 h2 {
    font-size: 34px;
    height: 150px;
    width: 150px;
    background-color: #03609c;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    border-radius: 50%;
    color: #fff;
    letter-spacing: 1px;
}
.showscore-sec-2-cnt.prfl-1 p {
    text-transform: uppercase;
    font-weight: 500;
    color: gray;
    letter-spacing: 1px;
}
.showscore-sec-2-cnt.showscore-prfl-1 {
    display: flex;
    justify-content: center;
    position: relative;
    top: 5rem;
}
.showscore-raees h5 {
    position: relative;
    right: 1.4rem;
}

.showscore-raees h5 {
    position: relative;
    right: 1.4rem;
    font-weight: 500;
    color: white;
}
.showscore-scr {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 8px 0px;
    align-items: center;
}
.showscore-scr .showscore-scr-name {
    flex: 1;
    padding-right: 10px;
}
.showscore-scr .showscore-data-name {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffa500;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
}
.showscore-sec-1 {
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    max-width: calc(100% - 32px);
    margin: 18px auto;
}
.showscore-sec-1 h5 {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--f1) !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
.showscore-sec-1 .showscore-scr-data:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.showscore-scr-1 {
    border-radius: 10px 10px 0 0;
}
.showscore-scr-2{
    border-radius:  0 0 10px 10px;

}
.progress-1 #progressbar1 {
    background-color: white;
    border-radius: 10px;
    padding: 3px;
    width: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    max-width: calc(100% - 32px);
    margin: 0 auto;
}
  
  #progressbar1>div {
    background-color: orange;
    width: 40%;
    height: 35px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 7px;
  }
  div#progressbar1 p {
    margin-bottom: 0;
    color: #fff;
}
.showscore-btn {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}
.showscore-btn .btn {
    flex: 1 1;
    max-width: 48%;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .5px;
    padding: 9px 11px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    line-height: 1.2;
}
.showscore-btn .btn-orange{
    background-color: orange;
    border-color: orange;
    color: #fff;
}
.showscore-btn .btn-info{
    background-color: #03609c;
    border-color: #03609c;
    color: #fff;
}