.main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.head-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 190px;
    display: block;
}
.gameheader .head-cnt {
    padding: 15px 16px 20px;
    background-color: #fff;
}
.gameheader .head-cnt h5 {
    margin-bottom: 2px;
}
.head-cnt p {
    font-size: 13px;
    color: #acabb0;
    margin-bottom: 8px;
}
p.para-1 {
    display: flex;
}
.gameheader .head-cnt p.para-1 span {
    background-color: #fff8eb;
    margin-right: 10px;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: orange;
    border: 1px solid #ffa50021;
}
.gameheader .head-cnt .btn-1 {
    margin-top: 15px;
    padding: 0;
}
p.para-1 i {
    margin-right: 6px;
}
.gameheader .head-cnt .btn-1 button {
    border-radius: 5px;
    border: 1px solid orange;
    background-color: #fff;
    color: orange;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
}
.gameheader .head-cnt .btn-1 button:hover,
.gameheader .head-cnt .btn-1 button:focus{
    background-color: orange;
    color: #fff;
}
.sec-2-cnt p {
    font-size: 13px;
    color: orange;
}
.gameheader {
    margin: -50px auto 0;
    max-width: calc(100% - 32px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
}
.game_leader {
    margin: 20px auto 10px;
    max-width: calc(100% - 32px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 16px;
}
.game_leader>h5 {
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    font-family: var(--f1) !important;
}
.game_leader .ctgry {
    display: flex;
    flex-wrap: wrap;
}
.game_leader .ctgry button {
    flex: 1;
}
.game_leader .ctgry button:not(:last-child) {
    margin-right: 5px;
}
.game_leader .ctgry button {
    flex: 1;
    border: 1px solid #ddd;
    font-size: 11px;
    text-align: center;
    background-color: #fff;
    text-transform: uppercase;
    padding: 6px 10px;
    font-weight: 500;
}
.game_leader .ctgry button.active {
    background-color: #03609c;
    color: #fff;
    border-color: #03609c;
}
.game_leader p.para-2 {
    margin-top: 10px;
    border: 1px solid #e3eef4;
    background-color: #e3eef4;
    color: #03609c;
    text-align: center;
    font-weight: 500;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 13px;
    text-transform: uppercase;
}
.game_leader .sec-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.game_leader .sec-2 .sec-2-cnt {
    flex: 0 0 31%;
    max-width: 31%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 10px 5px;
}
.game_leader .sec-2 .prfl-1,
.game_leader .sec-2 .prfl-2 {
    margin-top: 30px !important;
}
.game_leader .sec-2 .sec-2-cnt .raees img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: block;
    overflow: hidden;
    margin-bottom: 6px;
}
.game_leader .sec-2 .sec-2-cnt h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}
.game_leader .sec-2 .sec-2-cnt p {
    margin-bottom: 0;
    font-size: 12px;
}
.game_leader .position-img img {
    max-width: 90%;
    margin: 0 auto;
    display: table;
    margin-bottom: 15px;
    margin-top: -26px;
}
.game_leader .footer button img {
    max-width: 100%;
    height: 40px;
    margin: 0 auto;
    display: table;
}
.game_leader .footer button {
    padding: 0 !important;
    display: table;
    margin: 0 auto;
    border-radius: 7px;
    overflow: hidden;
}