.entercode-quiz-logo {
    text-align: center;
}
.entercode-quiz-logo img {
    width: 150px;
    margin: 0 auto;
    display: block;
}
.entercode-text-1 p {
    color: black;
}
.entercode-otp-input {
    border-radius: 7px;
    /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
}
.button-class {
    padding: 12px 18px!important;
    width: 100%;
    border-radius: 7px;
    color: white;
    background: #0362a2;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    cursor: pointer;
}
.entercode-ancr-2 a {
    position: relative;
    font-size: 14px;
    cursor: pointer;
}
.entercode-ancr-2 button {
    margin-top: 1rem;
    background-color: transparent !important;
    padding: 0;
}