.login-quiz-logo {
    text-align: center;
}
.login-quiz-logo img {
    width: 150px;
    margin: 0 auto;
    display: block;
}
.login-text-1 p {
    color: gray;
}
.login-para-2 h5 {
    font-size: 14px;
    font-weight: 500;
}
input#otp {
    position: relative;
    border-radius: 7px;
    padding: 8px 16px;
    min-height: 42px;
    border: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: 10px;
    box-shadow: none;
    /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
}
.login-button {
    padding: 10px 18px;
    width: 100%;
    border-radius: 7px;
    margin-bottom: 5px;
    color: white;
    background: #0362a2;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    cursor: pointer;
}
.login-ancr-2 a {
    font-size: 14px;
    cursor: pointer;
    color: orange;
}
.login-ques-pic img {
    width: 225px;
    height: 160px;
}
.login-para-1 h5 {
    font-weight: bold;
    margin-bottom: 5px;
}
.login-back-arw button {
    /* position: absolute;
    top: 20px;
    left: 20px; */
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: 12px;
    border: 1px solid #0362a2;
    border-radius: 50%;
    line-height: normal;
    color: #0362a2;
    background-color: #fff;
}
.login-back-arw i{
    color: inherit;
}
.login-back-arw button:hover{
    background-color: #0362a2;
    color: #fff;
}

.login-button-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}