.selectlanguage-main {
    background-image: url('../Images/bg.jpg');
    background-size: cover;
    background-position: center;
    background-color: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 1;
}
.selectlanguage-main:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #0164a1;
    z-index: -1;
    pointer-events: none;
    mix-blend-mode: multiply;
    opacity: .8;
    filter: hue-rotate(265deg);
}
.selectlanguage-main-2 {
    max-width: 400px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 30px 30px;
}
.selectlanguage-quiz-logo {
    text-align: center;
}
.selectlanguage-quiz-logo img {
    width: 150px;
    margin: 0 auto;
    display: block;
}
.selectlanguage-ancr-1 button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    background: #05629f;
    width: 100%;
}
.cusRadio label {
    padding: 8px 15px;
    display: block;
    position: relative;
    border: 1px solid #eee;
    border-radius: 7px;
    line-height: normal;
    margin-bottom: 6px;
    transition: all .3s ease;
    cursor: pointer;
}
.cusRadio {
    position: relative;
}
.cusRadio input {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
}
.cusRadio label:after {
    content: "\f111";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
    color: #ddd;
}
.cusRadio input:checked + label {
    background-color: #0164a1;
    border-color: #0164a1;
    color: #fff;
}
.cusRadio input:checked + label::after {
    content: "\f058";
    color: #fff;
}

@media screen and (max-width: 767px) {
    .selectlanguage-main-2 {
        padding: 10px 20px 30px;
    }
}