.editprofile-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.editprofile-body-cnt-1 {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.cusprofile_head {
    display: flex;
    align-items: center;
}
.editprofile-prfl-img img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.editprofile-upper-nav {
    display: flex;
    justify-content: space-between;
}
.editprofile-nav-b-cnt {
    display: flex;
}
.editprofile-nav-b {
    background-color: white;
    padding: 20px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}
.editprofile-nav-b-cnt h5 {
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
}
.cusprofile_head .editprofile-prfl-img {
    flex: 0 0 60px;
    margin-right: 8px;
    height: 60px;
    position: relative;
}
.cusprofile_head .editprofile-prfl-img button {
    position: absolute;
    bottom: -8px;
    right: 0;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03609c;
    color: #fff;
    border: 2px solid #fff;
    font-size: 9px;
}
.cusprofile_head .editprofile-h5-4 {
    flex: 1;
}
.editprofile-change-btn button {
    padding: 8px;
    background: orange;
    border: 2px solid orange;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    bottom: 10px;
    position: relative;
}
.editprofile-lower-nav-cnt {
    display: flex;
}
.editprofile-icons-1 {
    margin: 2rem 0 0 1rem;
    display: flex;
    gap: 15px;
}
.editprofile-icon-1 i {
    margin-top: 6px;
    margin-left: 0px;
}
.editprofile-icon-1 {
    background-color: rgba(0,0,0,.5);
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 5px;
}
.editprofile-icon-2 i {
    margin-top: 6px;
    margin-left: 0;
}
.editprofile-nav-b-cnt button {
    display: flex;
}
.editprofile-icon-2 {
    background-color: rgba(0,0,0,.5);
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 5px;
}
.editprofile-h5-4 h5 {
    font-weight: bold;
    font-size: 16px;
}
.cusprofile_head p {
    font-size: 13px;
    font-weight: 500;
    color: gray;
    padding-top: 2px;
}
.editprofile-h5-5 h5 {
    font-weight: 500;
    margin: 0 0 1rem 2rem;
}
.editprofile_desc {
    padding-top: 1em;
}
.editprofile-para-6 p {
    color: gray;
    font-size: 12px;
}

.editprofile-para-6 {
    display: flex;
    justify-content: center;
    padding: 0 1rem 0 1.5rem;
}
.editprofile-last-btn button {
    padding: 8px 25px;
    background: orange;
    border: 3px solid orange;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
}
.editprofile-icon-1 button {
    background-color: rgba(0,0,0,.01);
    width: 30px;
    height: 30px;
    border-radius: 5px;
}
.editprofile-icon-2 button {
    background-color: rgba(0,0,0,.01);
    width: 30px;
    height: 30px;
    border-radius: 5px;
}