.rewards-main {
    max-width: 575px;
    min-height: 100vh;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.rewards-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 70px 20px;
    background-color: #03609c;
    border-radius: 0 0 20px 20px;
}
.rewards-nav-1 .reward_title .rewards-logo-rewards img {
    height: 45px;
    border-radius: 50%;
}
.rewards-nav-1 .reward_title .rewards-para-1 button {
    color: #fff;
    background: transparent !important;
    border: none !IMPORTANT;
    outline: none !important;
    box-shadow: none !important;
}
.rewards-nav-1>button {
    height: 32px;
    width: 32px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50%;
    align-self: center;
    font-size: 12px;
    margin-right: 10px;
    flex: 0 0 32px;
}
.rewards-nav-1>button:hover, .rewards-nav-1>button:focus {
    background-color: #fff;
    color: #03609c;
}
.rewards-nav-1 {
    display: flex;
}
.rewards-nav-1 .reward_title .rewards-para-1 {
    display: inline-block;
    line-height: normal;
}
.reward_title {
    display: flex;
    align-items: center;
}
.reward_title .rewards-logo-rewards {
    flex: 0 0 45px;
    margin-right: 6px;
}
.rewards-nav-1 .reward_title .rewards-para-1 button h5{
    margin-bottom: 0;
    font-size: 18px;
}
.rewards-prfl-img-2 img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
    margin-left: 5px;
}
.rewards-nav-2 {
    display: flex;
}
/* .rewards-nm-cnt h5 {
    font-weight: 500;
    color: white;
    font-size: 12px;
}
.rewards-nm-cnt {
    margin-right: 1rem;
} */
.rewards-nav-1 button {
    background-color: #03609c;
}
/* .rewards-nm-cnt p {
    font-size: 10px;
    color: white;
} */
.rewards-upr-body-cnt h5 {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--f1) !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}
.rewards-back-data {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
}
.rewards-back-data .rewards-received {
    flex: 1;
    padding: 0 10px;
    text-align: center;
}
.rewards-received p {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 4px;
    background-color: #333;
    border-radius: 50%;
    color: #fff;
}
.rewards-received h5 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    font-family: var(--f1) !important;
    line-height: normal;
    margin-bottom: 0;
}
.rewards-green p {
    background-color: green;
}
.rewards-green h5{
    color: green;
}

.rewards-orange p {
    background-color: orange;
}
.rewards-orange h5{
    color: orange;
}
.rewards-body-cnt {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: 16px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.rewards-body-cnt>h5 {
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    font-family: var(--f1) !important;
}
.rewards-upr-body-1 {
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    margin-top: -50px;
    /* box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px; */
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.rewards-upr-body {
    width: 100%;
    padding: 0 1rem;
}
hr {
    color: orange;
    margin: 1rem 0 0.5rem 0;
}
span.rewards-icon-1 i {
    position: relative;
    color: green;
}
span.rewards-h5-1 h5 {
    color: green;
    font-weight: 500;
}
span.rewards-p-1 {
    display: flex;
    justify-content: center;
    background-color: green;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
span.rewards-icon-2 i {
    color: orange;
}
span.rewards-h5-2 h5 {
    color: orange;
    font-weight: 500;
}
span.rewards-p-2 {
    display: flex;
    justify-content: center;
    background-color: orange;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.rewards-rewards-img img {
    width: 150px;
    display: inline-block;
}
.rewards-rewards-img {
    text-align: center;
}
.rewards-last-cnt {
    text-align: center;
    padding: 0 15px;
}
.rewards-last-cnt p {
    font-size: 14px;
    line-height: 1.3;
}
.rewards-leauge-1 {
    margin-top: 5px;
    text-align: center;
    margin-bottom: 10px;
}
.rewards-leauge-1 button {
    padding: 10px 20px;
    background-color: #0064a2;
    border: none;
    border-radius: 5px;
    color: white;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
}
@media screen and (max-width: 375px){}